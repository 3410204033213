@import "@/styles/_variables.scss";






















































.ad {
  cursor: pointer;
}

.selected {
  border: 1px solid var(--app-primary-color);
}

.title {
  font-size: 14px;
}

