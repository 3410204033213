@import "@/styles/_variables.scss";

























































































































.fixed {
  height: 55vh;
}
